.collapserCtn {
    margin-bottom: rem(16px);
}

.collapserBox {
    margin-bottom: rem(10px);
}

.collapserContent {
    height: 0;

    div.clearfix {
        padding: rem(20px) rem(20px) 0;

        @include breakpoint(huge) {
            padding: rem(30px) rem(30px) rem(10px);
        }
    }
}

.collapser {
    background-color: $secondary;
    padding: rem(20px);
    padding-right: rem(50px);
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
    color: $light;
    font-weight: 700;
    transition: background-color .5s;
    font-size: rem(17px);

    body.noJS & {
        color: $dark;
    }

    @include breakpoint(huge) {
        font-size: rem(18px);
    }

    &:hover {
        background-color: $primary;
    }

    &:not(.collapser-initialized) {
        background: none;
        padding:0;

        &:after, &:before {
            display:none;
        }
    }

    &:after, &:before {
        background: $light;
        width:20px;
        height: 3px;
        content:"";
        position: absolute;
        right: rem(20px);
        transition:.5s;
    }

    &:after {
        top: 50%;
    }

    &:before {
        transform: rotate(90deg);
        top:50%;
    }

    &:not(.active) {
        overflow: hidden;

        & ~ .collapserContent {
            opacity: 0;
            transition: .5s;
        }
    }

    &.active, body.noJS & {
        
        &:before {
            transform: rotate(-135deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        & ~ .collapserContent {
            height: 100%;
            background-color: rgba($dark, .05);
            transition: .5s;
            
            opacity: 1;


        }
    }

    &.active {
        background-color: $secondary;
    }

    & + * {
        overflow:hidden;
        transition: max-height 300ms;


        &.limited {
            overflow-y:scroll;
            margin-bottom: 1rem;

        }
    }   
}