@include fontFaceV2(
 $fontName: "icomoon",
 $fileName: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);

@include fontFaceV2(
    $fontName: "Spartan MB", 
    $fileName: "spartanmb", 
    $weights: ("300", "700", "800"), 
    $types: all
);