
// HEADER
//////////////////////////////

#header {
	margin-top: rem(50px);
	position: relative;

	@include breakpoint(giant) {
		margin-top: rem(120px);
	}

	@include breakpoint(giant2) {
		margin-top: rem(130px);
	}

	@include breakpoint(huge) {
		margin-top: rem(140px);
	}

	@include breakpoint(huge2) {
		margin-top: rem(162px);
	}

	.mobileBranding {
		background-color: $light;
		
		box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
		display: block;
		left: 50%;
		padding: rem(10px) rem(18px);
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		width: rem(240px);
		z-index: 2;

		@include breakpoint(medium) {
			left: 0;
			transform: none;
		}

		.branding {
			opacity: 1;
			transition: opacity .5s;

			&:hover {
				opacity: .7;
			}
		}

		@include breakpoint(giant) {
			display: none;
		}
	}

	.headerCtn {
		position: relative;

		.headerImg {
			background-position: center center;
			background-size: cover;
			height: rem(290px);
			@include responsiveImage('layout/headerBg.jpg', (tiny, small, medium, large, giant, huge), false);

			body:not(.index) & {
				height: rem(250px);
			}

			@include breakpoint(medium) {
				background-position: right center;
				height: calc(100vh - #{rem(200px)});
				min-height: rem(660px);

				body:not(.index) & {
					min-height: 0;
				}
			}

			@include breakpoint(large) {
				body:not(.index) & {
					height: rem(270px);
				}
			}

			@include breakpoint(giant) {
				background-position: center center;
				min-height: rem(500px);
			}

			@include breakpoint(huge) {
				height: calc(100vh - #{rem(270px)});
			}
		}

		.claimCtn {

			@include breakpoint(medium) {
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
			}

			.claimBox {
				display: flex;
				justify-content: center;
				padding: rem(30px) 0;

				@include breakpoint(tiny) {
					padding: rem(40px) 0;
				}

				@include breakpoint(medium) {
					background-color: $light;
					box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
					justify-content: flex-start;
					margin-top: rem(100px);
					padding: rem(40px) rem(40px);
				}

				@include breakpoint(large) {
					max-width: rem(626px);
					padding: rem(50px) rem(50px);
				}

				@include breakpoint(giant) {
					margin-top: 0;
				}

				@include breakpoint(giant2) {
					max-width: rem(952px);
					padding: rem(50px) rem(60px) rem(60px);
				}

				.claimContent {
					text-align: center;

					@include breakpoint(medium) {
						text-align: left;
					}

					.claim {
						margin-bottom: rem(16px);

						@include breakpoint(tiny) {
							margin-bottom: rem(20px);
						}

						@include breakpoint(small) {
							margin-bottom: rem(25px);
						}

						@include breakpoint(giant2) {
							margin-bottom: rem(30px);
						}

						.headline {
							color: $primary;
							display: block;
							font-size: rem(24px);
							font-weight: 800;
							line-height: rem(30px);

							@include breakpoint(tiny) {
								font-size: rem(26px);
								line-height: rem(32px);
							}

							@include breakpoint(small) {
								font-size: rem(28px);
								line-height: rem(32px);
							}

							@include breakpoint(medium) {
								font-size: rem(28px);
								line-height: rem(40px);
								margin: 0 0 0.5rem;
							}

							@include breakpoint(large) {
								font-size: rem(32px);
								line-height: rem(36px);
							}

							@include breakpoint(giant2) {
								font-size: rem(38px);
								line-height: rem(50px);
								margin: 0 0 0.5rem;
							}
						}

						.subline {
							display: block;
							font-size: rem(18px);
							margin-top: rem(5px);

							@include breakpoint(medium) {
								font-size: rem(20px);
								line-height: rem(32px);
								margin: 0 0 1.5rem;
							}

							@include breakpoint(large) {
								font-size: rem(26px);
								line-height: rem(38px);
							}

							@include breakpoint(giant2) {
								font-size: rem(28px);
								line-height: rem(40px);
								margin: 0 0 1.5rem;
							}
						}

						.subsubline {
							display: block;
							font-size: rem(18px);
							font-weight: 700;
							line-height: 30px;
							margin: 0;
						}
					}

					.buttons {
						display: flex;
						gap: 1rem;
						flex-wrap: wrap;
						@include breakpoint(medium, max) {
							justify-content: center;
						}
						a {
							background-color: $secondary;
							border-radius: rem(33px);
							color: $light;
							display: inline-block;
							font-weight: 700;
							padding: rem(12px) rem(30px);
							transition: background-color .5s, color .5s;

							@include breakpoint(large) {
								padding: rem(12px) rem(26px);
							}

							@include breakpoint(giant) {
								font-size: rem(17px);
								line-height: rem(27px);
								padding: rem(12px) rem(33px) rem(13px);
							}

							@include breakpoint(giant2) {
								font-size: rem(18px);
								line-height: rem(28px);
								padding: rem(12px) rem(38px) rem(13px);
							}

							@include breakpoint(huge) {
								font-size: rem(19px);
								line-height: rem(29px);
								padding: rem(13px) rem(38px) rem(14px);
							}

							@include breakpoint(huge2) {
								padding: rem(13px) rem(39px) rem(14px);
							}

							i {
								font-size: 110%;
								font-weight: 400;
								margin-bottom: 2px;
								margin-left: -4px;
								margin-right: rem(5px);

								@include breakpoint(giant) {
									margin-bottom: 3px;
								}
							}

							&.btn1 {
								&:hover, &:focus, &:active {
									background-color: $primary;
								}
							}

							&.btn2 {
								background-color: $light;
								color: $secondary;
								outline: 2px solid $secondary;
								outline-offset: -2px;
								&:hover, &:focus, &:active {
									background-color: $secondary;
									color: $light;
								}
							}
						}
					}
				}
			}
		}
	}

	.fixedSocials {
		display: none;

		@include breakpoint(giant) {
			align-items: flex-end;
			display: flex;
			flex-direction: column;
			position: fixed;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			z-index: 10;

			.box {
				display: flex;
				margin-bottom: rem(5px);
				position: relative;

				.dataCtn {
					align-items: center;
					background-color: $primary;
					color: $light;
					display: flex;
					font-size: rem(15px);
					height: rem(50px);
					opacity: 0;
					padding: 0 rem(20px);
					pointer-events: none;
					position: absolute;
					right: 100%;
					transition: opacity .5s, visibility .5s;
					visibility: hidden;
					white-space: nowrap;

					a {
						color: $light;
						opacity: 1;
						transition: opacity .5s;

						&:hover {
							opacity: .6;
						}
					}
				}

				.iconCtn {
					align-items: center;
					background-color: $secondary;
					box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
					color: $light;
					display: flex;
					font-size: rem(23px);
					height: rem(50px);
					justify-content: center;
					width: rem(50px);

					img {
						height: rem(19px);
						width: rem(19px);
					}
				}

				&:hover {
					cursor: pointer;

					.dataCtn {
						opacity: 1 !important;
						pointer-events: all !important;
						visibility: visible !important;
					}
				}

				&.phoneBox {
					.iconCtn {
						font-size: rem(22px);
					}
				}

				&.openingBox {
					.iconCtn {
						font-size: rem(22px);
					}
				}

				&.mailBox {
					.iconCtn {
						font-size: rem(25px);
					}

					a {
						transition: .5s;
					}
				}
			}
		}
	}
}

// MAIN CONTENT //

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom: rem($baseGap*2);
	}

	.teaserCtn {
		margin-top: rem(10px);

		@include breakpoint(medium) {
			margin-top: rem(40px);
		}

		@include breakpoint(giant) {
			margin-top: rem(60px);
		}

		@include breakpoint(huge) {
			margin-top: rem(80px);
		}

		.teaserRow {
			display: flex;
			flex-wrap: nowrap;
			overflow-x: auto;
			padding-bottom: rem(10px);


			@include breakpoint(giant) {
				flex-wrap: wrap;
				justify-content: center;
				overflow: visible;
				padding: 0;
			}

			.col {
				.teaser {
					display: block;
					margin-bottom: rem(10px);
					overflow: hidden;
					width: 100%;

					@include breakpoint(giant) {
						box-shadow: 0px 0px 35px 0px rgba(0,0,0,0.1);
						margin-bottom: rem(36px);

						&.teaser4, &.teaser5, &.teaser6 {
							margin-bottom: 0;
						}
					}

					.imgCtn {
						position: relative;
						width: 100%;
						height: Min(40vw, #{rem(220px)});

						&:before {
							background-color: $primary;
							content: '';
							height: 100%;
							left: 0;
							opacity: 0;
							position: absolute;
							top: 0;
							transition: opacity .5s;
							width: 100%;
							z-index: 2;
						}

						img {
							position: absolute;
							inset: 0;
							object-fit: cover;
							margin-bottom: 0;
							width: 100%;
							height: 100%;
						}
					}

					.linkCtn {
						background-color: $secondary;
						color: $light;
						font-size: rem(15px);
						font-weight: 700;
						transition: background-color .5s;
						width: 100%;
						padding: 1rem;

						@include breakpoint(giant) {
							font-size: rem(14px);
						}

						@include breakpoint(giant2) {
							font-size: rem(16px);
						}

						@include breakpoint(huge) {
							font-size: rem(18px);
						}

						@include breakpoint(huge2) {
							font-size: rem(19px);
						}
						span {
							&::after {
								content: map-get($iconMap, arrow-right);
								font-family: $iconFont;
								margin-left: .5rem;
							}
						}
						.infoText {
							display: block;
							font-weight: normal;
							font-size: rem(16px);
							margin-top: .5rem;
							margin-bottom: 0;
						}
					}

					&:hover {
						.imgCtn {
							&:before {
								opacity: .3;
							}
						}

						.linkCtn {
							background-color: $primary;
						}
					}
				}
			}
		}
	}

	.contentCtn {
		padding: rem(50px) 0;

		@include breakpoint(medium) {
			padding: rem(60px) 0;
		}

		@include breakpoint(large) {
			padding: rem(80px) 0;
		}

		@include breakpoint(giant) {
			padding: rem(100px) 0;
		}

		@include breakpoint(giant2) {
			padding: rem(120px) 0;

			body:not(.index) & {
				padding: rem(100px) 0;
			}
		}

		@include breakpoint(huge) {
			padding: rem(140px) 0;

			body:not(.index) & {
				padding: rem(110px) 0;
			}
		}
	}
	.contentCtnBottom {
		padding-bottom: rem(50px);

		@include breakpoint(medium) {
			padding-bottom: rem(60px);
		}

		@include breakpoint(large) {
			padding-bottom: rem(80px);
		}

		@include breakpoint(giant) {
			padding-bottom: rem(100px);
		}

		@include breakpoint(giant2) {
			padding-bottom: rem(120px);

			body:not(.index) & {
				padding-bottom: rem(100px);
			}
		}

		@include breakpoint(huge) {
			padding-bottom: rem(140px);

			body:not(.index) & {
				padding-bottom: rem(110px);
			}
		}
	}

	.mobileTop {
		padding-bottom: 0;

		@include breakpoint(large) {
			padding: rem(80px) 0;
		}

		@include breakpoint(large) {
			padding: rem(90px) 0;
		}

		@include breakpoint(giant) {
			padding: rem(100px) 0;
		}

		@include breakpoint(giant2) {
			padding: rem(120px) 0;
		}

		@include breakpoint(huge) {
			padding: rem(140px) 0;
		}
	}

	.onlyBottom {
		padding-top: 0;
	}

	.cut1 {
		padding: 0;

		@include breakpoint(medium) {
			padding-left: rem($baseGap);
		}
	}

	.cut2 {
		padding: 0;

		@include breakpoint(medium) {
			padding-right: rem($baseGap);
		}
	}

	.contentImgBox {
		background-position: center center;
		background-size: cover;
		height: rem(300px);

		@include breakpoint(tiny) {
			height: rem(400px);
		}

		@include breakpoint(medium) {
			box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.06);
		}

		@include breakpoint(large) {
			height: 100%;
		}

		&.contentImg1 {
			@include responsiveImage('index/contentImg.jpg', (tiny, small), true);
			background-position: center center;
			background-size: cover;
		}

		&.contentImg2 {
			@include responsiveImage('index/contentImg2.jpg', (tiny, small), true);
			background-position: center center;
			background-size: cover;
		}
	}

	.contentBox {
		&.contentBox1 {
			padding-bottom: rem(40px);

			@include breakpoint(medium) {
				padding: rem(40px) 0;
			}

			@include breakpoint(large) {
				padding: rem(60px) 0;
			}

			@include breakpoint(giant2) {
				padding: rem(80px) 0;
			}

			@include breakpoint(huge) {
				padding: rem(100px) 0;
			}
		}
		&.contentBox2 {
			padding-top: rem(40px);

			@include breakpoint(small) {
				padding: rem(50px) 0;
			}

			@include breakpoint(medium) {
				padding: rem(40px) 0;
			}

			@include breakpoint(large) {
				padding: rem(60px) 0;
			}

			@include breakpoint(giant2) {
				padding: rem(80px) 0;
			}

			@include breakpoint(huge) {
				padding: rem(100px) 0;
			}
		}
	}

	a {
		color: $secondary;
		transition: color .5s;

		&:hover {
			color: $primary;
		}
	}

	.anfahrt-description {
		p {
			display: flex;
			gap: 1rem;
			align-items: baseline;
			&::before {
				font-family: $iconFont;
				font-size: 1.8em;
				position: relative;
				top: .3rem;
			}
			&.parking {
				&::before {
					content: map-get($iconMap, parking);
				}
			}
			&.car {
				&::before {
					content: map-get($iconMap, car);
				}
			}
			&.bus {
				&::before {
					content: map-get($iconMap, bus);
				}
			}
		}
	}

	.ansprechpartner-item {
		background-color: lighten($medium, 20%);
		padding: 1.5rem;
		margin-bottom: rem($baseGap);
		@include breakpoint(large) {
			height: 100%;
		}
		.defaultList {
			list-style: disc;
			padding-left: 1.5rem;
			li {
				padding-block: .5rem;
				+ li {
					border-top: 1px solid lighten($medium, 15%);
				}
				&::marker {
					color: $primary;
				}
			}
		}
		h4 {
			color: $dark;
		}
		.contacts {
			.phone {
				&::before {
					content: map-get($iconMap, phone);
				}
			}
			.email {
				&::before {
					content: map-get($iconMap, paper-plane);
				}
			}
			.whatsapp {
				&::before {
					content: map-get($iconMap, whatsapp);
				}
			}
		}
	}
}

.auftragsablaufbereich {
	.ablaeufe {
		position: relative;

		@include breakpoint(medium) {
			padding-left: rem(80px);
			padding-right: rem(80px);	
		}

		@include breakpoint(large) {
			padding-left: 0;
			padding-right: 0;
		}

		.distance {
			padding-bottom: 4rem;
			padding-top: calc(35px + 4rem);
		}

		p {
			margin-bottom: rem(18px);
		}

		.btn {
			font-size: rem(16px);
			margin-top: rem(0);
		}

		.kontaktaufnahme, .beratung, .bearbeitung {
			background: $light;
			border: 1px solid $dark;
			hyphens: auto;
			padding: calc(35px + 1rem) 2rem 2rem 2rem;
			position: relative;
	
			@include breakpoint(large) {padding: 2rem;}
			@include breakpoint(giant) {padding: 3rem;}

			i {
				align-items: center;
				background: $dark;
				border: 10px solid $light;
				border-radius: 50%;
				color: $light;
				display: flex;
				font-size: rem(30px);
				height: rem(70px);
				justify-content: center;
				left: 50%;
				position: absolute;
				top: -30px;
				transform: translateX(-50%);
				transition-delay: 250ms;
				width: rem(70px);
				z-index: 10;

				@include breakpoint(large) {
					font-size: rem(40px);
					height: 125px;
					left: auto;
					padding: rem(20px);
					right: calc((153px + 20%)*-1);
					top: 50%;
					transform: translate(-50%, -50%) scale(1);
					width: 125px;
				}
			}

			img {
				border-right: 1px solid $dark;
				height: 100%;
				left: 0;
				object-fit: cover;
				position: absolute;
				top: 0;
				width: rem(80px);

				@include breakpoint(medium) {
					width: rem(220px);
				}

				@include breakpoint(large) {
					width: rem(120px);
				}

				@include breakpoint(full) {
					width: rem(234px);
				}
			}

			@include breakpoint(large) {
				&:after {
					background: $dark;
					content:"";
					display: block;
					height: 1px;
					left: auto;
					position: absolute;
					right: 0;
					top: 50%;
						transform: translateX(100%);
					transition: 500ms all ease-in-out;
					width: 20%;
					z-index: 1;
				}
			}
			
		}

		.beratung {
			margin: rem(90px) 0;

			@include breakpoint(large) {
				margin: -20px 0;

				&:after {
					left: 0;
					transform: translateX(-100%);
					width: 20%;
				}
			}

			i {
				align-items: center;
				background: $dark;
				border: 10px solid $light;
				border-radius: 50%;
				color: $light;
				display: flex;
				font-size: rem(30px);
				height: rem(70px);
				justify-content: center;
				left: 50%;
				position: absolute;
				top: -30px;
				transform: translateX(-50%);
				transition-delay: 250ms;
				width: rem(70px);
				z-index: 10;

				@include breakpoint(large) {
					font-size: rem(40px);
					height: 125px;
					left: calc((26px + 20%)*-1);
					padding: rem(20px);
					right: auto;
					top: 50%;
					transform: translate(-50%, -50%) scale(1);
					width: 125px;
				}
				
			}


		}

		.loading-line {
			background: $secondary;
			border-radius: 5px;
			height: 100%;
			left: 50%;
			position: absolute;
			top: 0;
			transform: translateX(-50%);
			width: 10px;
			z-index: -1;
		}
	}
}

#privacyPolicy {
	a {
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER //

#footer {
	.videoCtn {
		display: block;
		height: rem(200px);
		width: 100%;
		
		@include breakpoint(tiny) {
			height: rem(250px);
		}

		@include breakpoint(medium) {
			height: rem(300px);
		}

		@include breakpoint(large) {
			height: rem(390px);
			// margin-top: rem(100px);
		}

		@include breakpoint(giant) {
			height: rem(450px);
			// margin-top: rem(110px);
		}

		@include breakpoint(giant2) {
			height: rem(500px);
			// margin-top: rem(120px);
		}

		@include breakpoint(huge) {
			height: rem(550px);
			// margin-top: rem(130px);
		}

		@include breakpoint(huge2) {
			height: rem(600px);
			// margin-top: rem(140px);
		}

		@include breakpoint(final) {
			height: rem(655px);
			// margin-top: rem(160px);
		}

		.video {
			align-items: center;
			background-position: center center;
			background-size: cover;
			display: flex;
			height: 100%;
			justify-content: center;
			position: relative;
			width: 100%;
			@include responsiveImage('index/videoBg.jpg', (tiny, small, medium, large, giant, huge), true);

			@include breakpoint(giant) {
				background-attachment: fixed;
			}

			&:before {
				background-color: #000;
				content: '';
				height: 100%;
				left: 0;
				opacity: .3;
				position: absolute;
				top: 0;
				transition: opacity .5s;
				width: 100%;
				z-index: 1;
			}

			.text {
				align-items: center;
				background-color: $secondary;
				border-radius: rem(33px);
				color: $light;
				display: flex;
				font-family: $displayFont;
				font-size: rem(15px);
				font-weight: 600;
				justify-content: center;
				padding: rem(11px) rem(25px) rem(10px);		
				position: relative;
				text-align: center;
				transition: background-color .5s, color .5s, border .5s;
				z-index: 2;

				@include breakpoint(tiny) {
					font-size: rem(17px);
				}

				@include breakpoint(small) {
					font-size: rem(20px);
					padding: rem(16px) rem(28px);
				}

				@include breakpoint(medium) {
					font-size: rem(22px);
					padding: rem(20px) rem(40px) rem(20px);
				}

				@include breakpoint(giant) {
					font-size: rem(24px);
					line-height: rem(24px);
					padding: rem(23px) rem(50px) rem(21px);
				}

				@include breakpoint(huge) {
					border-radius: rem(45px);
					font-size: rem(28px);
					height: rem(75px);
					line-height: rem(28px);
					padding: rem(30px) rem(40px) rem(33px);
					width: rem(347px);
				}

				i {
					font-size: rem(18px);
					font-weight: 400;
					line-height: rem(18px);
					margin-left: -5px;

					@include breakpoint(tiny) {
						font-size: rem(19px);
						line-height: rem(19px);
					}

					@include breakpoint(small) {
						font-size: rem(22px);
						line-height: rem(22px);
						margin-top: 0;
					}

					@include breakpoint(medium) {
						font-size: rem(24px);
						line-height: rem(24px);
						margin-left: -7px;
					}

					@include breakpoint(giant) {
						font-size: rem(24px);
						line-height: rem(24px);
						margin-left: -10px;
					}

					@include breakpoint(huge) {
						font-size: rem(28px);
						line-height: rem(28px);
						margin-left: -14px;
						margin-top: 2px;
					}
				}
			}

			&:hover {
				cursor: pointer;

				.text {
					background-color: $primary;
				}

				&:before {
					opacity: 0;
				}
			}
		}
	}

	.maps {
		height: rem(400px);

		@include breakpoint(huge) {
			height: rem(500px);
		}

		@include breakpoint(final) {
			height: rem(550px);
		}

		.googleMaps {
			height: 100%;
			padding: 0;
		}
	}

	.contactRow {
		padding: rem(40px) rem(20px);

		div {
			margin-bottom: rem(20px);

			&:last-child {
				margin-bottom: 0;
			}

			img {
				width: auto;
			}
		}

		.flexCtn {
			align-items: center;
			display: flex;
			flex-direction: column;
			text-align: center;
			gap: 2rem;
		}

		@include breakpoint(small) {
			padding: rem(60px) rem(20px);

			.flexCtn {
				align-items: flex-start;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				text-align: left;
			}

			div {
				&.left, &.middle {
					margin-bottom: rem(30px);
				}

				&.left {
					margin-right: rem(50px);
				}
			}
		}

		@include breakpoint(medium) {
			padding: rem(80px) rem(40px);
		}

		@include breakpoint(large) {
			padding: rem(100px) rem(40px);

			.flexCtn {
				align-items: flex-end;
			}

			div {
				&.left, &.middle, &.right {
					margin-bottom: 0;
				}

				&.middle, &.left {
					margin-right: rem(35px);
				}
			}
		}

		@include breakpoint(giant) {
			padding: rem(120px) 0;

			.col {
				display: flex;
				justify-content: center;
			}

			.flexCtn {
				justify-content: space-between;
				width: rem(928px);
			}
		}

		@include breakpoint(huge) {
			padding: rem(140px) rem(40px);
		}
	}

	.footerImg {
	    @include responsiveImage('layout/footerImg.jpg', (tiny, small, medium, large, giant, huge), false);
	    background-position: center;
	    background-size: cover;
	    height: rem(300px);
	}

	@include breakpoint(small) {
		.footerImg {
			height: rem(350px);
		}
	}

	@include breakpoint(medium) {
		.footerImg {
			height: rem(400px);
		}
	}

	@include breakpoint(large) {
		.footerImg {
			height: rem(450px);
		}
	}

	@include breakpoint(giant) {
		.footerNav {
			background-color: $primary;
			padding: rem(20px) 0;

			.col {
				display: flex;
				justify-content: center;
			}
		}

		.footerImg {
			background-attachment: fixed;
			height: rem(650px);
		}
	}

	@include breakpoint(giant2) {
		.footerNav {
			padding: rem(30px) 0;
		}

		.footerImg {
			height: rem(750px);
		}
	}

	@include breakpoint(huge) {
		.footerImg {
			height: rem(800px);
		}
	}

	@include breakpoint(cut) {
		.footerImg {
			height: rem(900px);
		}
	}
}
.logosCtn {
	padding: rem(60px) rem($baseGap);

	@include breakpoint(large) {
		padding: rem(80px) rem($baseGap);
	}

	@include breakpoint(giant) {
		padding: rem(120px) rem($baseGap);
	}

	@include breakpoint(huge2) {
		display: flex;
		justify-content: center;
		padding: rem(140px) rem($baseGap);
	}

	.logos {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		@include breakpoint(giant) {
			justify-content: center;
			overflow: auto;
			padding: 0;
		}

		.imgCtn {
			margin-right: rem(30px);

			@include breakpoint(tiny) {
				margin-right: rem(40px);
			}
			@include breakpoint(giant) {
				min-width: 10% !important;
			}
			
			&.img1 {
				min-width: rem(284px);

				@include breakpoint(tiny) {
					min-width: rem(337px);
				}
			}

			&.img2 {
				min-width: rem(202px);
			}

			&.img3 {
				min-width: rem(184px);
			}

			&.img4{
				min-width: rem(259px);
			}

			&.img5{
				margin-right: 0;
				min-width: rem(283px);
			}
		}
	}

	.swipeSlide {
		color: $dark !important;
		display:block;
		font-family: $displayFont;
		font-size:rem(17px);
		line-height:rem(27px);
		margin-top: rem(20px);
		text-align:center;

		@include breakpoint(giant) {
			margin-top: rem(30px);
		}

		@include breakpoint(huge) {
			display:none;
		}
		
		i {
			font-size:rem(34px);
			font-style: normal;
			font-weight:400;
			padding-left:rem(6px);
			vertical-align:middle;
		}
	}
}

.swipeSlide {
    color: $dark !important;
    display:none;
    font-family: $displayFont;
    font-size:rem(17px);
    line-height:rem(27px);
    margin-top: rem(5px);
    text-align:center;

    body.index & {
    	display:block;

	    @include breakpoint(giant) {
	        display:none;
	    }
    }

    i {
    	font-size:rem(34px);
    	font-style: normal;
    	font-weight:400;
    	padding-left:rem(6px);
    	vertical-align:middle;
    }
}

.popupToggler {
	display: none;

	&:checked {

		+ .popupBackground {
			opacity: 1;
			visibility: visible;

			+ .popupClose {
				opacity: 1;
				visibility: visible;

				+ .popupContainer {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

.popupBackground, .popupClose, .popupContainer {
	cursor:pointer;
	opacity:0;
	position:fixed;
	visibility:hidden;
}

.popupBackground {
	background:rgba(#000,0.85);
	bottom:0;
	left:0;
	top:0;
	width:100%;
	z-index:2000;
}

.popupClose {
	color:$light;
	font-size:rem(50px);
	line-height:100%;
	right:rem($baseGap);
	top:rem($baseGap);
	z-index: 1999;;
}

.popupContainer {
	display:flex;
	height:rem(500px);
	left:50%;
	overflow:hidden;
	overflow-y:auto;
	padding:0 rem($baseGap);
	top:50%;
	transform:translate(-50%,-50%);
	width:rem(315px);
	z-index:2001;
	
	@include breakpoint(tiny) {
		height:rem(470px);
		width:rem(480px);
	}

	@include breakpoint(small) {
		height:rem(460px);
		width:rem(640px);
	}

	@include breakpoint(medium) {
		height:rem(428px);
		width:rem(760px);
	}

	@include breakpoint(large) {
		height:rem(659px);
		width:rem(992px);
	}

	.popupContent {
		align-items: center;
		display:flex;
		height: 0;
		padding-bottom: 56.25%;
		position: relative;
		width: 100%;

		iframe {
			height:100%;
			left:0;
			position:absolute;
			top:0;
			width:100%;
		}

		p {
		    background-image:none !important;
			color:$dark;
			display:block;
		    font-family:$mainFont;
		    font-size:rem(22px);
		    font-weight:300;
		    line-height:rem(32px);
		    margin-bottom:0;
			padding:rem(20px);
			text-align:center;
			width:100%;

		    @include breakpoint(giant) {
		    	padding:rem(50px);
		    }
		}
	}
}

.btnFocus {
	transition: none;
}

.nonbold {
	font-weight: 300 !important;
}

ul.subnav {

	li {
		display: flex;

		a {
			align-items: center;
			background-color: rgba($medium, .15);
			color:$dark;
			display: flex;
			flex-direction: column;
			padding: rem(30px) rem(30px);
			text-align: center;
			text-decoration: none;
			transition: background-color .5s;

			@include breakpoint(giant) {
				padding: rem(40px) rem(40px);
			}

			.btn {
				background-color: transparent;
				border: 2px solid $primary;
				color: $primary;
				display: block;
				font-weight: 700;
				padding: rem(14px) rem(30px);
				transition: color .5s, background-color .5s;
				width: 100%;

				@include breakpoint(medium) {
					padding: rem(16px) rem(40px);
				}
			}

			&:hover {
				background-color: rgba($medium, .35);

				.btn {
					background-color: $primary;
					color: $light;
				}
			}

			span {

				&.name {
					color: $dark;
					font-family: $displayFont;
					font-size: 1.2em;
					margin-bottom: rem(10px);

					@include breakpoint(giant) {
						font-size: 1.4em;
					}
				}

				&.description {
					flex-grow:1;
					font-weight: 300;
					margin-bottom: rem(15px);
				}
			}
		}
	}
}

body:not(.javascript) * { transition:none !important;}

.collapserContent  {
	.hr {
		@include breakpoint(tiny) {
		    margin: rem(20px) auto rem(45px);
		  }
	}

	.imgContainer {
		margin-bottom: rem(36px);
	}
}

a {
	&.pdf {
		display: inline-block;
		font-weight: 700;
		margin-bottom: rem(36px);
		margin-right: rem(20px);
		text-decoration: none;
		
		&:before {
			content: '\eac5';
			font-family: $iconFont;
			font-size: 1.2em;
			margin-left: -2px;
			margin-right: rem(5px);
			position: relative;
			top: 2px;
		}
	}
}

.wowBox {
	margin-bottom: rem(36px);
	body:not(.scrollingDown):not(.scrollingUp):not(.cmsBackend) & {
		display: none;
	}
}

.bewertungMargin {
	margin-bottom: 2rem;
}

.bewertungs-h2 {
	text-align: center;
}

.bewertungsBox {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 40px 0px;
	height: 100%;
	padding: 16px * 2;
	.dateBox{
		p{
			margin-bottom: 0;
		}
	}
	.bewertungsKopf{
		color: $light;
		display: block;
		font-weight: bold;
		justify-content: space-between;
		margin-bottom: 2rem;
		@include breakpoint(medium){
			align-items: center;
			display: flex;
		}
		.nameBox{
			align-items: center;
			display: flex;
			p{
				color: $dark;
				margin-bottom: 0;
			}
			.anfangsBuchstabe{
				align-items: center;
				background: #d56a6a;
				border-radius: 50%;
				display: flex;
				height: 48px;
				justify-content: center;
				margin-right: 12px;
				width: 48px;
			}
		}
	}

	.bewertungsSterne {
		padding: rem(8px) 0;

		svg {
			fill: #f3d779;
		}

		i {
			color: #f3d779;
		}
	}
}
 a.ProvenExpert_widget_container {
	text-decoration:none;
	z-index:999;
	position: relative;
	display: block;
	line-height:0;
	
	@include breakpoint(large) {
		position:fixed;
		left:0;
		top:rem(300px);
		float:right;
	}
	img {
		border: 0;
	}
 }