// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: rem(13px) rem(12px);
	text-decoration: none;
	color: $navColor;
	font-size: rem(14px);
	line-height: rem(22px);
	border-bottom: 1px dotted rgba($dark,.3);
	transition: background-color .5s;
	text-transform: uppercase;

	@include breakpoint(tiny) {
		font-size: rem(15px);
		line-height: rem(23px);
	}

	@include breakpoint(small) {
		font-size: rem(15px);
	}

	@include breakpoint(medium) {
		padding: rem(15px) rem(12px);
	}

	@include breakpoint(large) {
		padding: rem(17px) rem(15px);
	}

	@include breakpoint(giant) {
		border-bottom: none;
		font-size: rem(15px);
		padding: 0;
		position: relative;
	}

	@include breakpoint(giant2) {
		font-size: rem(17px);
	}

	@include breakpoint(huge) {
		font-size: rem(18px);
	}
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $dark;
	font-weight: 600;

	@include breakpoint(giant) {
		background-color: transparent;
		color: $primary;
		font-weight: 600;
	}
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $primary;

	@include breakpoint(giant) {
		background-color: transparent;
		color: $primary;
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, arrow-right);
	transform: rotateY(180deg);
	color: $primary;

	@include breakpoint(giant) {
		color: $primary;
	}

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, arrow-right);
	color: $primary;

	@include breakpoint($break) {
		transform:rotate(90deg);
		color: $primary;
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $primary; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

#navigation {
	width: 100%;
	transition: transform .1s;

	@include breakpoint (giant, max){
		overflow-y: scroll;
		top: 4rem;
	}

	.brandingCtn {
		display: none;
		@include breakpoint(giant) {
			display: flex;
			width: 26%;
			justify-content: flex-end;
			align-items: center;

			@include breakpoint(huge2) {
				width: 28%;
			}

			.branding {
				display: block;
				width: rem(210px);
				transition: width .5s, opacity .5s;

				&:hover {
					opacity: .7;
				}

				body.scroll &, body.noJS & {
					width: rem(170px);
				}

				@include breakpoint(giant2) {
					width: rem(210px);

					body.scroll &, body.noJS & {
						width: rem(190px);
					}
				}

				@include breakpoint(huge) {
					width: rem(230px);

					body.scroll &, body.noJS & {
						width: rem(200px);
					}
				}

				@include breakpoint(huge2) {
					width: rem(260px);

					body.scroll &, body.noJS & {
						width: rem(230px);
					}
				}
			}
		}
	}
	
	.naviBox {
		display: flex;
		width: 100%;
		flex-direction: column-reverse;

		@include breakpoint(giant) {
			height: 100%;
			flex-direction: row;
		}

		@include breakpoint(giant) {
			justify-content: space-around;
			align-items: center;
			padding: 0 rem($baseGap) 0 rem($baseGap);
		}

		@include breakpoint(huge2) {
			max-width: rem(1620px);
		}

		.navCtn {
			width: 100%;
			height: 100%;
			border-left: 1px solid rgba($dark,.3);

			&.right {
				display: none;

				@include breakpoint(giant) {
					display: flex;
					width: 37%;
					height: auto;
					padding-bottom: 0;
					overflow: visible;
					border: none;
				}

				@include breakpoint(huge2) {
					width: 36%;
				}
			}

			&.left {
				@include breakpoint(giant) {
					width: 37%;
					height: auto;
					padding-bottom: 0;
					overflow: visible;
					border: none;
				}

				@include breakpoint(huge2) {
					width: 36%;
				}
			}

			.navBox {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: 100%;
			}
		}

		.mobileNavContact {
			width: 50%;
			height: 100%;
			display: none;
			justify-content: center;
			padding-right: rem(8px);
			padding-left: rem(8px);
			text-align: right;
			padding-bottom: rem(40px);
			padding-top: rem(40px);
			overflow-y: scroll;
			color: $dark;

			@include breakpoint(small) {
				align-items: center;
			}

			@include breakpoint(giant) {
				display: none;
			}

			.contact {
				.address, .openingtimes, .telefon, .telefax, .mail {
					margin-bottom: rem(20px);

					@include breakpoint(medium) {
						margin-bottom: rem(20px);
					}
					.headline {
						display: block;
						font-size: rem(13px);
						text-transform: uppercase;
						line-height: rem(20px);
						color: $dark;
						font-weight: 700;
						margin-bottom: rem(3px);

						@include breakpoint(tiny) {
							font-size: rem(15px);
							line-height: rem(24px);
						}

						@include breakpoint(small) {
							font-size: rem(16px);
							line-height: rem(21px);
							margin-bottom: rem(5px);
						}

						i {
							font-weight: 400;
							margin-top: -2px;
						}
					}

					.addressData, .openingData {
						font-size: rem(13px);
						line-height: rem(19px);

						@include breakpoint(tiny) {
							font-size: rem(14px);
							line-height: rem(22px);
						}

						@include breakpoint(small) {
							font-size: rem(15px);
							line-height: rem(22px);
						}

						span {
							display: block;
						}

						.smallMargin {
							margin-bottom: rem(4px);
						}
					}
				}

				a {
					font-size: rem(13px);
					color: $dark;
					display: block;

					@include breakpoint(tiny) {
						font-size: rem(14px);
						line-height: rem(21px);

						&.naviLink {
							margin-bottom: 60px;
						}
					}

					@include breakpoint(small) {
						font-size: rem(15px);
						line-height: rem(22px);
					}
				}
			}
		}
	}
}

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		display: flex;
		width: 100%;
		height: 100%;
		
		@include breakpoint(small) {
			align-items: center;
		}

		@include breakpoint(giant) {
			padding-top: 0;
			width: 100%;
		}

		@include ul {
			&.navi {
				body.iphone & {
					margin-top: rem(30px)
				}
				li {
					&.datenschutzerklaerung {
						display: none;
					}

					&.customData {
						display: none;
					}

					&.startseite {
						a {
							border-top: 1px dotted rgba($dark,.3);

							@include breakpoint(giant) {
								border: none;
							}
						}
					}

					@include navButtons {
						@include navButton;
					}

					&:hover, &.active {

						a, span {

							&:after {
								color: $light;

								@include breakpoint(giant) {
									color: $primary;
								}
							}
						}
					}
					@include hasSub {
						a, span {
							&:after {
								margin-left: 0 !important;
								transition: color .5s;
							}

							&:hover, &.active {
								&:after {
									color: $light;
								}
							}

							@include breakpoint(giant) {
								&:hover, &.active {
									&:after {
										color: $primary;
									}
								}
							}
						}
						@include sub {
							background: $navBg;
							transition: transform .1s;
							display: flex;
							flex-direction: column;
							justify-content: flex-start;
							padding-bottom: rem(60px);

							.unsereLeistungen {
								display: none;
							}

							@include breakpoint(tiny) {
								width: 50%;
								left: 50%;
								border-left: 1px solid rgba($dark,.3);
							}

							@include breakpoint(giant) {
								transform: translateX(-50%);
								background: transparent;
								padding-top: rem(8px);
							}

							&:before {
								color: $light;
								padding: 1em rem(12px);
								text-transform: uppercase;
								font-size: rem(14px);
								border-bottom: 1px dotted rgba($dark,.5);
								border-top: 1px dotted rgba($dark,.5);
								background-color: $secondary !important;

								@include breakpoint(small) {
									font-size: rem(16px);
								}

								@include breakpoint(medium) {
									font-size: rem(17px);
								}
							}

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {

						@include breakpoint(giant) {
							display: none;
						}
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		background-color: $light;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		height: rem(140px);
		background-image: none !important;
		transition: height .5s, padding-bottom .5s;

		@include breakpoint(giant2) {
			height: rem(150px);
		}

		@include breakpoint(huge) {
			height: rem(140px);
		}

		@include breakpoint(huge2) {
			height: rem(162px);
		}

		body.scroll &, body.noJS & {
			box-shadow: 0px 0px 43px 0px rgba(0,0,0,0.06);
			height: rem(150px);

			@include breakpoint(giant2) {
				height: rem(140px);
			}

			@include breakpoint(huge) {
				height: rem(130px);
			}

			@include breakpoint(huge2) {
				height: rem(150px);
			}
		}

		nav {
			ul {
				&.mobile {
					li {
						&.aktuelles, &.kontakt, &.anfahrt {
							display: none;
						}
					}
				}
				&.desktop {
					li {
						&.customData {
							display: inline-block;

							.phone {
								background-color: $secondary;
								color: $light;
								font-weight: 700;
								padding: rem(11px) rem(30px);
								border-radius: rem(33px);

								@include breakpoint(giant2) {
									padding: rem(12px) rem(33px);
									border-radius: rem(36px);
								}

								@include breakpoint(huge) {
									padding: rem(14px) rem(36px);
									border-radius: rem(36px);
								}

								@include breakpoint(huge2) {
									padding: rem(15px) rem(39px);
									border-radius: rem(45px);
								}

								&:hover {
									background-color: $primary;
									color: $light;
								}

								i {
									font-weight: 400;
									margin-left: -4px;

									@include breakpoint(giant2) {
										margin-top: -2px;
										margin-left: -6px;
									}
								}
							}
						}
					}
				}
				&.navi {
					align-items: center;
					li {
						a {
							transition: color .5s, text-shadow .5s;
						}

						&.datenschutzerklaerung, &.impressum, &.faq, &.anfahrt {
							display: none;
						}

						&.hasSub {
							.subToggler  {
								transition: color .5s;

								&:hover {
									&:after {
										color: $primary;
									}
								}

								&.active {
									&:after {
										color: $primary;
									}
								}
							}
							.sub {
								min-width: 100%;
								border-left: none;
								transition: opacity .5s;
								padding-top: rem(10px);

								li {
									a {
										transition: color .5s, background-color .5s;
										text-shadow: none;
										text-transform: none;
										font-weight: 300;
										font-size: .9em;
										text-align: center;
										background-color: $primary;
										color: $light;
										border-bottom: 1px solid rgba($secondary, .3);

										@include breakpoint(giant) {
											padding: rem(10px) rem(20px);
										}

										@include breakpoint(giant2) {
											padding: rem(15px) rem(20px);
										}
									}
									&:hover {
										a {
											background-color: $secondary;
											color: $light;

											&:before {
												display: none;
											}
										}
									}

									&.active {
										a {
											color: $light;
											background-color: $secondary;

											&:before {
												display: none;
											}
										}
									}
								}
							}
						}
						&.navBack {
							transition: background-color .5s;
							&:before {
								font-weight: 300;
								font-size: .9em !important;
								text-align: center;
								color: $light;
							}

							&:hover {
								background-color: $dark;
							}
							
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	border-bottom: 1px solid rgba($light, .5);

	.phone {
		font-weight: 700;
	}

	.quickbarRight {
		display: flex;
		align-items: center;

		.phone {
			width: 23px;
			height: auto;
			margin-right: rem(15px);
			display: block;
			margin-bottom: 4px;
		}
	}

	@include breakpoint($break) {
		display: none;
	}
}

.naviAdd {
	display: none;

	@include breakpoint(giant) {
		display: flex;
		justify-content: space-between;
		position: relative;
		width: rem(928px);

		li {
			a {
				color: $light;
				font-size: rem(15px);
				text-decoration: none;
				opacity: 1;
				transition: .4s;
				text-transform: uppercase;

				&:hover, &.active {
					opacity: 0.6;
				}

				@include breakpoint(giant2) {
					font-size: rem(17px);
				}
			}
		}
	}
}