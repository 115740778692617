.icon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: $iconFont;
  vertical-align: middle;
}

@each $name, $ico in $iconMap {
  // .icon-#{$name}
  .icon-#{$name}:before {
    content: "#{$ico}";
  }
}
